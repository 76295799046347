import { makeStyles, Theme } from "@material-ui/core"
import Img, { GatsbyImageProps } from "gatsby-image"
import React, { ReactElement } from "react"

const useStyles = makeStyles<Theme, GatsbyImageProps>({
  container: {
    backgroundColor: (props): string => `${props.backgroundColor}`,
    overflow: "hidden",
  },
  img: {
    transform: "scale(1) rotate(.02deg) translateZ(0)",
    transition: "transform 1s ease-out",

    "&:hover": {
      transform: "scale(1.025) rotate(.02deg) translateZ(0)",
      transition: "transform .5s ease-in",
    },
  },
})

function HoverImg(props: GatsbyImageProps): ReactElement {
  let { backgroundColor } = props
  if (typeof backgroundColor === "boolean") {
    backgroundColor = backgroundColor ? "lightgray" : "inherit"
  } else if (typeof backgroundColor === "undefined") {
    backgroundColor = "inherit"
  }
  const classes = useStyles({ ...props, backgroundColor })
  return (
    <div className={classes.container}>
      <Img className={classes.img} {...props} />
    </div>
  )
}

export default HoverImg
